<template>
  <div class="mobile-height">
    <v-layout wrap>
      <v-flex xs12 sm8>
        <div>
          <div class="headline d-flex align-center justify-start">
            <span>Find campaigns</span>
          </div>
          <span class="subtitle-1 light-grey"
            >All available campaigns on the platform</span
          >
        </div>
      </v-flex>
      <v-flex xs12 sm4 class="text-right">
        <!-- view mode switch -->
        <span v-show="items.length > 0">
          <v-tooltip
            bottom
            v-if="displayMode === 'card' && $vuetify.breakpoint.smAndUp"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                outlined
                class="px-3 mr-2"
                @click="changeListView('table')"
                v-show="displayMode === 'card'"
                v-on="on"
              >
                <v-icon small color="primary">fal fa-list</v-icon>
              </v-btn>
            </template>
            <span>Table view</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="displayMode === 'table' && $vuetify.breakpoint.smAndUp"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                outlined
                class="px-3 mr-2"
                @click="changeListView('card')"
                v-show="displayMode === 'table'"
                v-on="on"
              >
                <v-icon small color="primary">fal fa-table</v-icon>
              </v-btn>
            </template>
            <span>Grid</span>
          </v-tooltip>
        </span>
        <!-- filter -->
        <v-tooltip v-if="$vuetify.breakpoint.smAndUp" bottom>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges" :value="filtersCount">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  showFiltersDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
        <!--Mobile button-->
        <v-btn
          color="primary"
          class="hidden-sm-and-up second-fixed-bottom-right"
          fixed
          bottom
          right
          fab
          @click="changeListView('table')"
          v-show="items.length > 0 && displayMode === 'card'"
        >
          <v-icon color="white">fal fa-list</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="hidden-sm-and-up second-fixed-bottom-right"
          fixed
          bottom
          right
          fab
          @click="changeListView('card')"
          v-show="items.length > 0 && displayMode === 'table'"
        >
          <v-icon color="white">fal fa-table</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          @click="showFiltersDialog = true"
        >
          <v-icon color="white">fas fa-filter</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="items.length === 0 && !loading" class="mt-10">
      <div>There are no results for your search.</div>
      <img
        src="@/assets/searchFailedInfluencer.svg"
        alt="search Failed Influencer"
        class="default-img"
      />
    </div>
    <v-container
      fluid
      grid-list-xl
      class="pl-0 pr-0"
      v-if="items.length > 0 || !loading"
    >
      <v-layout wrap v-if="displayMode === 'card'">
        <v-flex
          xs12
          sm6
          md4
          lg4
          xl4
          class="w-100"
          v-for="item in items"
          :key="item.uuid"
        >
          <campaign-list-card :campaign="item" class="w-100">
            <template v-slot:footer>
              <v-btn
                color="primary"
                block
                :to="{ name: 'campaign', params: { id: item.uuid } }"
              >
                See campaign
              </v-btn>
            </template>
          </campaign-list-card>
        </v-flex>
      </v-layout>
      <v-card v-else>
        <v-card-text wrap class="p-0">
          <campaigns-data-table
            :isInfluencer="true"
            :items="items"
            :page="page"
            :total="total"
            :loading="loading"
            @changePage="loadCampaigns($event)"
          ></campaigns-data-table>
        </v-card-text>
      </v-card>
      <v-pagination
        v-if="displayMode === 'card' && items.length"
        v-model="page"
        class="my-2"
        :length="total"
        :total-visible="7"
        @input="handlePageChange"
      ></v-pagination>
    </v-container>
    <v-container fluid grid-list-xl class="pl-0 pr-0" v-else>
      <v-layout wrap class="skeletons-wrapper">
        <v-flex
          xl4
          lg4
          md6
          xs12
          v-for="(skeleton, i) of skeletonArray"
          :key="i"
        >
          <v-flex class="v-card influencer-skeleton">
            <v-skeleton-loader
              height="185px"
              type="card"
              class="card-header-image"
            ></v-skeleton-loader>
            <v-flex class="pr-0 pl-0 mb-3">
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </v-flex>
            <v-flex>
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-flex>
            <v-flex>
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-flex>
            <v-flex>
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </v-flex>
            <v-flex>
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </v-flex>
            <v-flex class="mb-4">
              <v-skeleton-loader type="heading"></v-skeleton-loader>
            </v-flex>
            <v-flex class="p-0">
              <v-skeleton-loader type="button" width="100%"></v-skeleton-loader>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>
    <find-campaign-filters-dialog
      v-model="showFiltersDialog"
      :filters="filters"
      @save="saveFilters"
    ></find-campaign-filters-dialog>
  </div>
</template>
<script>
import search_filters_url from "@/helpers/mixins/search_filters_url";
import FindCampaignFiltersDialog from "../dialogs/FindCampaignFiltersDialog";
import CampaignListCard from "../../cards/CampaignListCard";
import local_numbers from "@/helpers/filters/local_numbers";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import CampaignsDataTable from "../../data-tables/CampaignsDataTable";
import { mapActions } from "vuex";

export default {
  mixins: [search_filters_url],
  components: {
    CampaignListCard,
    FindCampaignFiltersDialog,
    CampaignsDataTable
  },
  filters: { local_numbers, friendly_numbers, utc_to_local_datetime },
  data: () => ({
    filters: {},
    loading: false,
    total: null,
    page: 1,
    items: [],
    skeletonArray: [],
    filtersUpdateTimeout: null,
    showFiltersDialog: false,
    displayMode: "card",
    prevRouteName: null
  }),
  computed: {
    filtersCount() {
      let filters = this.cloneDeep(this.filters);
      let count = Object.keys(filters).length;
      // If search key is set but has no value. Then subtract 1 from count
      if ("search" in filters && !filters.search) {
        count--;
      } else if ("limit" in filters && "page" in filters) {
        return count - 2;
      }
      return count;
    },
    isCampaginRoute() {
      return this.prevRouteName === "campaign" || !this.prevRouteName;
    }
  },
  methods: {
    ...mapActions("core/campaigns", { getCampaigns: "loadCampaigns" }),
    handlePageChange(value) {
      this.scrollToTop();
      this.page = value;
      this.loadCampaigns(value);
    },
    saveFilters(event) {
      this.filters = event;
      this.page = 1;
      this.loadCampaigns(this.page);
    },
    loadCampaigns(page = 1) {
      this.loading = true;
      this.page = page;
      this.addSkeletons();
      let filters = this.filters;

      if (filters.category_ids) {
        filters.category_ids = filters.category_ids.map(id => {
          return Number(id);
        });
      }
      filters.limit = 18;
      filters.page = page;
      const params = {
        ...filters,
        active_campaigns: true,
        with: ["influencers"],
        order_by_recommended: true
      };
      this.setUrlSearchFilters(filters);

      this.getCampaigns(params).then(campaigns => {
        this.total = campaigns.lastPage;
        this.items = campaigns.response;
        this.loading = false;
      });
    },
    changeListView(mode) {
      this.displayMode = mode;
      this.$nextTick(() => {
        window.scrollTo({ top: 0 });
      });
    },
    addSkeletons() {
      let arr = [];
      for (let i = 0; i < 12; i++) {
        arr.push({ id: "skeleton" });
      }
      this.skeletonArray = arr;
    }
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const filters = this.getUrlSearchFilters();
      this.filters = filters;
      this.page = filters.page;
    }
    this.loadCampaigns(this.page);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRouteName = from.name;
    });
  }
};
</script>
<style lang="scss">
.border-right {
  border-right: 1px solid #ccc;
}
.v-skeleton-loader__button {
  width: 100%;
}
.card-header-image {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 22px);
}
.second-fixed-bottom-right {
  bottom: 86px !important;
  padding: 0px !important;
}
@media only screen and (max-width: 960px) {
  .virtual-scroll-item {
    width: 50% !important;
  }
}
@media only screen and (max-width: 600px) {
  .virtual-scroll-item {
    width: 100% !important;
  }
  .mobile-height {
    padding-bottom: 120px;
  }
}
</style>
